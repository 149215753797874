import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDetail } from '@cargamos/angular';

import { environment as ENV } from '@environments/environment';
import { OverlayFacade } from '@modules/cds/facades/overlay.facade';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly router: Router = inject(Router);
  private readonly overlay: OverlayFacade = inject(OverlayFacade);
  private readonly authService: AuthService = inject(AuthService);
  private readonly translate: TranslateService = inject(TranslateService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.updateRequestHeaders(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isTokenExpired(error)) {
          return this.authService.refreshToken().pipe(
            tap((session: string | null) => {
              if (session) {
                this.authService.saveAuthToken(session);
              }
            }),
            switchMap(() => next.handle(this.updateRequestHeaders(request)))
          );
        }

        if (error.status === 403 && !this.canEndpointSkip403Page(request.url)) {
          this.goToForbidden();
          return EMPTY;
        }

        const errors: Array<ErrorDetail> = error.error?.errors;

        return throwError({
          errors: Array.isArray(errors) && errors.length > 0 ? errors : this.getDefaultError(),
          httpStatus: error?.status ?? 400,
        });
      })
    );
  }

  private isTokenExpired(error: HttpErrorResponse): boolean {
    return error.status === 401 && error.error.message === 'Jwt is expired';
  }

  private canEndpointSkip403Page(endpoint: string): boolean {
    return [].some((url: string) => endpoint.includes(url));
  }

  private updateRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let headers: HttpHeaders = request.headers
      .append('Accept-Language', 'es')
      .append('origin_timestamp', `${new Date().getTime()}`)
      .append('origin-platform', 'ADMIN');

    const newParams: {
      [param: string]: string;
    } = {};

    const url: string = request.url;
    const authToken = this.authService.getAuthToken();

    if (!request.headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }

    if (!request.headers.has('Authorization') && authToken) {
      headers = headers.append('Authorization', `Bearer ${authToken}`);
    }

    if (!request.headers.has('geolocation')) {
      headers = headers.append('geolocation', '0, 0');
    }

    if (!url.includes('key=')) {
      newParams.key = ENV.cargamos.apiKey;
    }

    return request.clone({
      headers,
      setParams: newParams,
    });
  }

  private goToForbidden(): void {
    const url = this.router.url.includes('/admin')
      ? ['/', 'admin', 'forbidden']
      : ['/', 'forbidden'];
    this.overlay.closeAllLoading();
    this.router.navigate(url, {
      skipLocationChange: true,
      queryParamsHandling: 'preserve',
    });
  }

  private getDefaultError(): Array<ErrorDetail> {
    return [
      {
        displayable_message: this.translate.instant('COMMON.GENERAL_ERROR'),
        code: 'UNKNOWN',
        details: this.translate.instant('COMMON.GENERAL_ERROR'),
        message: this.translate.instant('COMMON.GENERAL_ERROR'),
        parameter: 'UNKNOWN',
        location: 'UNKNOWN',
      },
    ];
  }
}
