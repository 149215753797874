import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  canActivate,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
  AuthGuard,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['/admin']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin',
  },
  {
    path: 'auth',
    loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule),
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@modules/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    canActivate: [AuthGuard],
    data: { icon: 'pie-chart', authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'not-found',
    loadChildren: () => import('@modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('@modules/forbidden/forbidden.module').then(m => m.ForbiddenModule),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
