import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from 'app/app.module';
import { APP_VERSION } from '@constants/app-version.constant';
import { environment as ENV } from '@environments/environment';

if (ENV.production) {
  enableProdMode();
}

if (ENV.sentry.enabled) {
  Sentry.init({
    environment: ENV.environmentName,
    dsn: ENV.sentry.apiKey,
    release: APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['services.connect.cargamos.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
