import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { I18nModule } from '@i18n/i18n.module';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as ENV } from '@environments/environment';
import { CdsCookieConsentBanner } from '@modules/cds/directives/proxies';

@NgModule({
  declarations: [AppComponent, CdsCookieConsentBanner],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    I18nModule,
    NgProgressModule,
    NgProgressRouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: ENV.enabledServiceWorker }),
    provideFirebaseApp(() => initializeApp(ENV.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
